import { FunctionComponent, lazy, Suspense, useEffect } from "react";
import { HashRouter, Navigate, NavLink, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { $isNull } from "app-helpers/basicFunctions";
import { UserModeEnum } from "app-model/enums/SysEnums";
import { IUser } from "app-model/IUser";

import { IReduxStoreProps } from "app-framework/AppBaseInterfaces";
import { userMapState } from "app-framework/AppCommonReduxMaps";
import { ensureRequiredGlobals } from "app-framework/AppDataHelpers";
import Spinner from "app-framework/spinner/Spinner";

import FullMenuRoutes from "app-layouts/FullMenuRoutes";
import FullShadowRoutes from "app-layouts/FullShadowRoutes";
import OnboardMenuRoutes from "app-layouts/OnboardMenuRoutes";
import OnboardShadowRoutes from "app-layouts/OnboardShadowRoutes";
import ReviewingMenuRoutes from "app-layouts/ReviewingMenuRoutes";
import ReviewingShadowRoutes from "app-layouts/ReviewingShadowRoutes";
import RouteMapper from "app-layouts/RouteMapper";
import icon from '../pageindexassets/images/Icons/wheel.svg'

const PageAbout = lazy(() => import("app-widgets/PageAbout"));
const PageContactUs = lazy(() => import("app-widgets/PageContactUs"));
const PageIndex = lazy(() => import("app-widgets/PageIndex"));

const PrimaryLayout = lazy(() => import("app-layouts/PrimaryLayout"));

export const LogonAuthenticatedRouteMap: FunctionComponent = () => {
	const reduxProps = useSelector<any, IReduxStoreProps>(userMapState);
	const dispatch = useDispatch();

	const userData: IUser = reduxProps.requiredGlobals.Global_MyUserView.entityData;
	// userData.UserMode = "gndjnfdj";
	
	const themeRoutes =
		$isNull(userData.UserMode) ? []
			: userData.UserMode == UserModeEnum.SteadyState ? [...FullMenuRoutes, ...FullShadowRoutes]
				: userData.UserMode == UserModeEnum.Reviewing ? [...ReviewingMenuRoutes, ...ReviewingShadowRoutes]
					: [...OnboardMenuRoutes, ...OnboardShadowRoutes];

	//console.log('userData.UserMode - ', userData.UserMode);

	//console.log('themeRoutes - ', themeRoutes);

	useEffect(
		() => {
			ensureRequiredGlobals({ dispatch, ...reduxProps });
		},
		[]
	);

	// /reviewing/999999999/view
	return (
		<Routes>
			<Route path="/home" element={<PageIndex />} />
			<Route path="/about" element={<PageAbout />} />
			<Route path="/contactus" element={<PageContactUs />} />

			<Route path="/" element={<PrimaryLayout />}>
				{$isNull(userData.UserMode)
					? null //no other routes will be defined, we will fall through to the catch-all route
					: themeRoutes.map((prop, key) => RouteMapper({ routeData: prop, routeKey: key }))}

				<Route path="*" element={
				
					<section className='tw-absolute tw-top-0 tw-right-0 tw-w-full lg:tw-w-[85%] tw-font-normal tw-text-black'>

						{/* <div className='hidden lg:flex col-span-1 items-center justify-center h-screen bg-gray-300'>
							<img src={icon} alt="" className='w-60' />
						</div> */}

						<div className='tw-text-center tw-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center tw-font-normal tw-gap-y-3 tw-p-3 tw-lg:p-0'>
							<img src={icon} alt="" className='tw-hidden lg:tw-block tw-w-36 tw-animate-[spin_10s_ease-in-out_infinite] tw-mb-8' />
							<p className='tw-text-4xl tw-font-semibold tw-text-center tw-leading-[30px] tw-animate-[pulse_2s_ease-in-out_infinite]'>
								Your Information Is Processing!
							{/* Your account is in review and you may not have access <br /> to this information at the moment. */}
							</p>
							<p className='tw-text-2xl tw-font-normal tw-text-center tw-leading-[30px]'>
							Please wait!
							</p>
							<p className='tw-text-base tw-font-light tw-mt-8'>
								You can explore our other services
								on our <br className='md:tw-hidden' />
								<NavLink to='/home' className='tw-text-2xl tw-text-blue-700 hover:tw-underline hover:tw-font-black hover:tw-text-vetivaGreen tw-duration-500'>Homepage</NavLink>
							</p>						
						</div>
					</section>
				} />
				
			</Route>
		</Routes>
	);
};

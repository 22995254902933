import { lazy } from "react";

const PartnerBankAcctList = lazy(() => import("app-modules/cashmgt/PartnerBankAcctList"));
const PartnerBeneficiaryList = lazy(() => import("app-modules/cashmgt/PartnerBeneficiaryList"));
const PartnerReceiptList = lazy(() => import("app-modules/cashmgt/PartnerReceiptList"));
const PartnerPaymentList = lazy(() => import("app-modules/cashmgt/PartnerPaymentList"));
const PartnerTransferList = lazy(() => import("app-modules/cashmgt/PartnerTransferList"));
const TermLoanList = lazy(() => import("app-modules/cashmgt/TermLoanList"));
const VALPartnerCashHistoryList = lazy(() => import("app-modules/cashmgt/VALPartnerCashHistoryList"));

const moduleRoutes = {
	collapse: true,
	path: "/cashmgt",
	name: "Cash Mgt",
	state: "cashmgtpages",
	icon: "codepen",
	//extra: "mega-dropdown",
	child: [
		{
			path: "/cashmgt/statement",
			name: "Statement",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: VALPartnerCashHistoryList,
		},
		{
			path: "/cashmgt/deposits",
			name: "Deposit",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: PartnerReceiptList,
		},
		{
			path: "/cashmgt/withdrawals",
			name: "Withdrawal",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: PartnerPaymentList,
		},
		{
			path: "/cashmgt/transfers",
			name: "Transfer",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: PartnerTransferList,
		},
		{
			path: "/cashmgt/termloan",
			name: "Loans",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: TermLoanList,
		},
		{
			path: "/cashmgt/beneficiaries",
			name: "Beneficiaries",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: PartnerBeneficiaryList,
		},
		{
			path: "/cashmgt/bankaccts",
			name: "Bank Accounts",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: PartnerBankAcctList,
		},
	],
};

export default moduleRoutes;

import { combineReducers } from 'redux';

import { toIsoDateString } from 'app-helpers/basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { DefNgNseExchangeId, EnumAllOptionValue, NullEntityId, oneYearAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-redux/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { PartnerState } from 'app-model/enums/FaEnums';

const stLists : IListComponentControlList = {
	ReviewingDocumentList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'ReviewingDocumentList',
		entityName: 'OnboardDocument',
		cmdUriTemplate: 'Onboard/Active/Documents',
		fetchUriTemplate: 'Onboard/Active/Documents',
		filterValues: {
			searchText: '',
		},
	},

	ReviewingRelationshipList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'ReviewingRelationshipList',
		entityName: 'OnboardRelationship',
		fetchUriTemplate: 'Onboard/Active/Relationships',
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	ReviewingView: {
		...fluxViewDefaults,
		actionNamespace: 'ReviewingView',
		entityName: 'Onboard',
		fetchUriTemplate: 'Onboard/Active',
		fetchUriComposited: true,
	},

	ReviewingInvestmentView: {
		...fluxViewDefaults,
		actionNamespace: 'ReviewingInvestmentView',
		entityName: 'OnboardInvestment',
		fetchUriTemplate: 'Onboard/Active/Investment',
		fetchUriComposited: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});

import { lazy } from "react";

const BondView = lazy(() => import("app-modules/research/BondView"));
const EquityView = lazy(() => import("app-modules/research/EquityView"));

const moduleRoutes = [
	{
		path: "/research/bonds/:id/view",
		name: "Bond Details",
		component: BondView,
	},
	{
		path: "/research/stocks/:id/view",
		name: "Stock Details",
		component: EquityView,
	},
];

export default moduleRoutes;

import { IRouteMenuData } from "utils/IRouteData";

import ReviewingRoutes from "app-modules/reviewing/_routemenu";


var ThemeRoutes: IRouteMenuData[] = [
	{
		navlabel: true,
		name: "Personal",
		icon: "mdi mdi-dots-horizontal",
	},

	ReviewingRoutes,

	{
		path: "/",
		pathTo: "/reviewing/999999999/view",
		name: "Dashboard",
		redirect: true,
	},
];

export default ThemeRoutes;

import { lazy } from "react";

const EQAccountList = lazy(() => import("app-modules/capitalmkt/EQAccountList"));
const EQContractNoteList = lazy(() => import("app-modules/capitalmkt/EQContractNoteList"));
const EQHoldingPryIssueList = lazy(() => import("app-modules/capitalmkt/EQHoldingPryIssueList"));
const EQOrderList = lazy(() => import("app-modules/capitalmkt/EQOrderList"));

const VALBondHtmHoldingList = lazy(() => import("app-modules/capitalmkt/VALBondHtmHoldingList"));
const VALHoldingAggregateList = lazy(() => import("app-modules/capitalmkt/VALHoldingAggregateList"));

const TransactionSummary = lazy(() => import("app-modules/dashboards/TransactionSummary"));

const moduleRoutes = 	{
	collapse: true,
	path: "/capitalmkt",
	name: "Capital Market",
	state: "capitalmktpages",
	icon: "codepen",
	child: [
		{
			path: "/capitalmkt/stockholdings",
			name: "Stock Holdings",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: VALHoldingAggregateList,
		},
		{
			path: "/capitalmkt/bondholdings",
			name: "Bond Holdings (HTM)",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: VALBondHtmHoldingList,
		},
		{
			path: "/capitalmkt/orders",
			name: "Trade Orders",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: EQOrderList,
		},
		{
			path: "/capitalmkt/contracts",
			name: "Trade Contracts",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: EQContractNoteList,
		},
		{
			path: "/capitalmkt/corpactions",
			name: "Corporate Actions",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: TransactionSummary,
		},
		{
			path: "/capitalmkt/coupons",
			name: "Coupons",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: TransactionSummary,
		},
		{
			path: "/capitalmkt/pryissues",
			name: "Pry Issue Subscriptions",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: EQHoldingPryIssueList,
		},
		{
			path: "/capitalmkt/tradingaccts",
			name: "Trading Accounts",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: EQAccountList,
		},
	],
};

export default moduleRoutes;

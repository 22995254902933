import { combineReducers } from 'redux';

import { toIsoDateString } from 'app-helpers/basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { DefNgNseExchangeId, EnumAllOptionValue, NullEntityId, oneYearAgo, today } from 'app-model/ModelConstants';
import { SecInstrumentState } from 'app-model/enums/EqEnums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-redux/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	BondList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'BondList',
		entityName: 'Bond',
		fetchUriTemplate: 'Bond',
		exportUriTemplate: 'Bond/ExportList',
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			enumParamInstrumentType: EnumAllOptionValue,
			enumParamClosureType: SecInstrumentState.Active,
		},
	},
	BondCorpActionSubList: {
		...fluxListDefaults,
		actionNamespace: 'BondCorpActionSubList',
		entityName: 'EQCorpActionTO',
		fetchUriTemplate: 'Bond(${subListParentId})/Trend',
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},
	BondNewsSubList: {
		...fluxListDefaults,
		actionNamespace: 'BondNewsSubList',
		entityName: 'ResNewsItemTO',
		fetchUriTemplate: 'Bond(${subListParentId})/Trend',
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},
	BondTrendSubList: {
		...fluxListDefaults,
		actionNamespace: 'BondTrendSubList',
		entityName: 'ResInstrumentBenchmarkTrend',
		fetchUriTemplate: 'Bond(${subListParentId})/BenchmarkTrend',
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	EQInstrumentPriceList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'EQInstrumentPriceList',
		entityName: 'EQInstrumentPrice',
		fetchUriTemplate: 'EQInstrumentPrice',
		exportUriTemplate: 'EQInstrumentPrice/ExportList',
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			// enumParamInstrumentType: EnumAllOptionValue,
			// enumParamClosureType: SecInstrumentState.Active,
			atDate: toIsoDateString(today),
		},
	},

	EquityList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'EquityList',
		entityName: 'Equity',
		fetchUriTemplate: 'Equity',
		exportUriTemplate: 'Equity/ExportList',
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			enumParamInstrumentType: EnumAllOptionValue,
			enumParamClosureType: SecInstrumentState.Active,
		},
	},
	EquityCorpActionSubList: {
		...fluxListDefaults,
		actionNamespace: 'EquityCorpActionSubList',
		entityName: 'EQCorpActionTO',
		fetchUriTemplate: 'Equity(${subListParentId})/CorpActions',
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},
	EquityNewsSubList: {
		...fluxListDefaults,
		actionNamespace: 'EquityNewsSubList',
		entityName: 'ResNewsItemTO',
		fetchUriTemplate: 'Equity(${subListParentId})/News',
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},
	EquityTrendSubList: {
		...fluxListDefaults,
		actionNamespace: 'EquityTrendSubList',
		entityName: 'ResInstrumentBenchmarkTrend',
		fetchUriTemplate: 'Equity(${subListParentId})/BenchmarkTrend',
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	RefExchangeDefList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'RefExchangeDefList',
		entityName: 'RefExchangeDef',
		fetchUriTemplate: 'RefExchangeDef',
		exportUriTemplate: 'RefExchangeDef/ExportList',
		filterValues: {
			searchText: '',
			// enumParamInstrumentType: EnumAllOptionValue,
			// enumParamClosureType: SecInstrumentState.Active,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	BondBenchmarkView: {
		...fluxViewDefaults,
		actionNamespace: 'BondBenchmarkView',
		entityName: 'ResInstrumentBenchmark',
		fetchUriTemplate: 'Equity(${id})/Benchmark',
		fetchUriComposited: true,
	},
	BondMarketValuesView: {
		...fluxViewDefaults,
		actionNamespace: 'BondMarketValuesView',
		entityName: 'EQInstrumentTechnicalInfoTO',
		fetchUriTemplate: 'Equity(${id})/Technicals',
		fetchUriComposited: true,
	},
	BondView: {
		...fluxViewDefaults,
		actionNamespace: 'BondView',
		entityName: 'Bond',
		fetchUriTemplate: 'Bond(${id})/DisplayItem',
		fetchUriComposited: true,
	},

	EquityBenchmarkView: {
		...fluxViewDefaults,
		actionNamespace: 'EquityBenchmarkView',
		entityName: 'ResInstrumentBenchmark',
		fetchUriTemplate: 'Equity(${id})/Benchmark',
		fetchUriComposited: true,
	},
	EquityMarketValuesView: {
		...fluxViewDefaults,
		actionNamespace: 'EquityMarketValuesView',
		entityName: 'EQInstrumentTechnicalInfoTO',
		fetchUriTemplate: 'Equity(${id})/Technicals',
		fetchUriComposited: true,
	},
	EquityView: {
		...fluxViewDefaults,
		actionNamespace: 'EquityView',
		entityName: 'Equity',
		fetchUriTemplate: 'Equity(${id})/DisplayItem',
		fetchUriComposited: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});

import { lazy } from "react";

//const EQOrderView = lazy(() => import("app-modules/capitalmkt/EQOrderView"));

const moduleRoutes = [
	// {
	// 	path: "/reviewing/:id/view",
	// 	component: EQOrderView,
	// },
];

export default moduleRoutes;

import { IRouteMenuData } from "utils/IRouteData";

import onboard from "app-modules/onboard/_routeshadow";


var ThemeRoutes: any[] = [
	...onboard,
];

export default ThemeRoutes;

import { lazy } from "react";

const MMPlacmentList = lazy(() => import("app-modules/moneymkt/MMPlacmentList"));
const MoneyMktOrderList = lazy(() => import("app-modules/moneymkt/MoneyMktOrderList"));
const TermInvestList = lazy(() => import("app-modules/moneymkt/TermInvestList"));
const TRTBillList = lazy(() => import("app-modules/moneymkt/TRTBillList"));
const TRCommPaperList = lazy(() => import("app-modules/moneymkt/TRCommPaperList"));
const TRPromNoteList = lazy(() => import("app-modules/moneymkt/TRPromNoteList"));

const moduleRoutes = {
	collapse: true,
	path: "/moneymkt",
	name: "Money Market",
	state: "moneymktpages",
	icon: "codepen",
	//extra: "mega-dropdown",
	child: [
		{
			path: "/moneymkt/orders",
			name: "MM Orders",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: MoneyMktOrderList,
		},
		{
			path: "/moneymkt/tbill",
			name: "Treasury Bills",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: TRTBillList,
		},
		{
			path: "/moneymkt/terminvest",
			name: "Term Investments",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: TermInvestList,
		},
		{
			path: "/moneymkt/mmplacement",
			name: "MM Placements",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: MMPlacmentList,
		},
		{
			path: "/moneymkt/commpaper",
			name: "Commercial Paper",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: TRCommPaperList,
		},
		{
			path: "/moneymkt/promnote",
			name: "Promissory Notes",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: TRPromNoteList,
		},
	],
};

export default moduleRoutes;

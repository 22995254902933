import { combineReducers } from 'redux';

import { toIsoDateString } from 'app-helpers/basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue, oneYearAgo, threeMonthsAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-redux/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { CurrencyCodes, PartnerState } from 'app-model/enums/FaEnums';
import { PxWorkflowStateFilter } from 'app-model/enums/SysEnums';

const stLists : IListComponentControlList = {
	PartnerBankAcctList: {
		...fluxListDefaults,
		actionNamespace: 'PartnerBankAcctList',
		entityName: 'PartnerBankAcct',
		fetchUriTemplate: 'PartnerBankAcct/CustomerBankAccts',
		exportUriTemplate: 'PartnerBankAcct/ExportCustomerBankAccts',
		filterValues: {
			searchText: '',
			enumParamCurrency: EnumAllOptionValue,
			enumParamClosureType: PartnerState.Active,
		},
	},

	PartnerBeneficiaryList: {
		...fluxListDefaults,
		actionNamespace: 'PartnerBeneficiaryList',
		entityName: 'PartnerBeneficiary',
		fetchUriTemplate: 'PartnerBeneficiary/CustomerBeneficiaries',
		exportUriTemplate: 'PartnerBeneficiary/ExportCustomerBeneficiaries',
		filterValues: {
			searchText: '',
			enumParamClosureType: PartnerState.Active,
		},
	},

	PartnerPaymentList: {
		...fluxListDefaults,
		actionNamespace: 'PartnerPaymentList',
		entityName: 'PartnerPayment',
		fetchUriTemplate: 'MyTransactions/Withdrawals',
		exportUriTemplate: 'MyTransactions/Withdrawals/Export',
		cmdUriTemplate: 'MyTransactions/Withdrawals',
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamCurrency: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	PartnerReceiptList: {
		...fluxListDefaults,
		actionNamespace: 'PartnerReceiptList',
		entityName: 'PartnerReceipt',
		fetchUriTemplate: 'MyTransactions/Deposits',
		exportUriTemplate: 'MyTransactions/Deposits/Export',
		cmdUriTemplate: 'MyTransactions/Deposits',
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamCurrency: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	PartnerTransferList: {
		...fluxListDefaults,
		actionNamespace: 'PartnerTransferList',
		entityName: 'PartnerTransfer',
		fetchUriTemplate: 'MyTransactions/Transfers',
		exportUriTemplate: 'MyTransactions/Transfers/Export',
		cmdUriTemplate: 'MyTransactions/Transfers',
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamCurrency: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	TermLoanList: {
		...fluxListDefaults,
		actionNamespace: 'TermLoanList',
		entityName: 'TermLoan',
		fetchUriTemplate: 'VALTermLoan/CustomerTermLoans',
		exportUriTemplate: 'VALTermLoan/ExportCustomerTermLoans',
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
		},
	},

	VALPartnerCashHistoryList: {
		...fluxListDefaults,
		actionNamespace: 'VALPartnerCashHistoryList',
		entityName: 'VALPartnerCashHistory',
		fetchUriTemplate: 'MyTransactions/CashHistory(${currency})',
		exportUriTemplate: 'MyTransactions/CashHistory(${currency})/Export',
		printUriTemplate: 'MyTransactions/CashHistory(${currency})/Print',
		filterValues: {
			searchText: '',
			currency: CurrencyCodes.NGN,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	PartnerPaymentView: {
		...fluxViewDefaults,
		actionNamespace: 'PartnerPaymentView',
		entityName: 'PartnerPayment',
		fetchUriTemplate: 'MyTransactions/Withdrawals(${entityId})',
		fetchUriComposited: true,
		entityId: -1,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});

import { FunctionComponent, lazy, Suspense } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";

import Spinner from "app-framework/spinner/Spinner";

const PageAbout = lazy(() => import("app-widgets/PageAbout"));
const PageContactUs = lazy(() => import("app-widgets/PageContactUs"));
const PageIndex = lazy(() => import("app-widgets/PageIndex"));

export const LogonUnauthenticatedRouteMap: FunctionComponent = () => {
	return (
		<Routes>
			<Route path="/home" element={<PageIndex />} />
			<Route path="/about" element={<PageAbout />} />
			<Route path="/contactus" element={<PageContactUs />} />

			<Route path="*" element={<Navigate replace to="/home" />} />
		</Routes>
	);
};

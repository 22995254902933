import { combineReducers } from 'redux';

import { toIsoDateString } from 'app-helpers/basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-redux/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	MMPlacmentList: {
		...fluxListDefaults,
		actionNamespace: 'MMPlacmentList',
		entityName: 'MMPlacment',
		fetchUriTemplate: 'MMPlacment/CustomerMMPlacments',
		exportUriTemplate: 'MMPlacment/ExportCustomerMMPlacments',
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
		},
	},
	MoneyMktOrderList: {
		...fluxListDefaults,
		actionNamespace: 'MoneyMktOrderList',
		entityName: 'PartnerPayment',
		fetchUriTemplate: 'MktOrder/CustomerMMOrders',
		exportUriTemplate: 'MktOrder/CustomerMMOrders',
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
		},
	},
	TermInvestList: {
		...fluxListDefaults,
		actionNamespace: 'TermInvestList',
		entityName: 'TermInvest',
		fetchUriTemplate: 'TermInvest/CustomerTermInvests',
		exportUriTemplate: 'TermInvest/ExportCustomerTermInvests',
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
		},
	},
	TRCommPaperList: {
		...fluxListDefaults,
		actionNamespace: 'TRCommPaperList',
		entityName: 'TRCommPaper',
		fetchUriTemplate: 'TRCommPaper/CustomerCommPapers',
		exportUriTemplate: 'TRCommPaper/ExportCustomerCommPapers',
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
		},
	},
	TRPromNoteList: {
		...fluxListDefaults,
		actionNamespace: 'TRPromNoteList',
		entityName: 'TRPromNote',
		fetchUriTemplate: 'TRPromNote/CustomerPromNotes',
		exportUriTemplate: 'TRPromNote/ExportCustomerPromNotes',
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
		},
	},
	TRTBillList: {
		...fluxListDefaults,
		actionNamespace: 'TRTBillList',
		entityName: 'TRTBill',
		fetchUriTemplate: 'TRTBill/CustomerTBills',
		exportUriTemplate: 'TRTBill/ExportCustomerTBills',
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	HRConfigView: {
		...fluxViewDefaults,
		actionNamespace: 'HRConfigView',
		entityName: 'HRConfig',
		fetchUriTemplate: 'HRConfig',
		entityId: -1,
		entityData: {},
		relatedData: {},
		error: false,
		isLoading: false,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});

import { combineReducers } from 'redux';

import { toIsoDateString } from 'app-helpers/basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { SecTransactionType } from 'app-model/enums/EqEnums';
import { PxWorkflowStateFilter } from 'app-model/enums/SysEnums';

import { EnumAllOptionValue, NullEntityId, threeYearsAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-redux/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	EQOrderOpenList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 25,
		actionNamespace: 'EQOrderOpenList',
		entityName: 'EQOrder',
		fetchUriTemplate: 'EQOrder/CustomerEQOrders',
		exportUriTemplate: 'EQOrder/ExportCustomerEQOrders',
		filterValues: {
			searchText: '',
			//exchangeId: NullEntityId,
			//instrumentId: NullEntityId,
			//enumParamTradeType: SecTransactionType.BUY,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	VALCustSummaryList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'VALCustSummaryList',
		entityName: 'VALCustSummary',
		fetchUriTemplate: 'MyPortfolio/ValuationHistory',
		exportUriTemplate: 'MyPortfolio/ValuationHistory/Export',
		printUriTemplate: 'MyPortfolio/ValuationHistory/Print',
		keyField: 'CustomerId',
		filterValues: {
			searchText: '',
			reportDate: toIsoDateString(today),
		},
	},

	VALSummaryTrendPointList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 100,
		actionNamespace: 'VALSummaryTrendPointList',
		entityName: 'VALSummaryTrendPoint',
		fetchUriTemplate: 'MyPortfolio/ValuationTrend',
		exportUriTemplate: 'MyPortfolio/ValuationTrend/Export',
		printUriTemplate: 'MyPortfolio/ValuationTrend/Print',
		keyField: 'CustomerId',
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	VALCustSummaryView: {
		...fluxViewDefaults,
		actionNamespace: 'VALCustSummaryView',
		entityName: 'VALCustSummary',
		fetchUriTemplate: 'MyPortfolio/ValuationSummary',
		exportUriTemplate: 'MyPortfolio/ValuationSummary/Export',
		printUriTemplate: 'MyPortfolio/ValuationSummary/Print',
		fetchUriComposited: true,
		entityId: -5,
		error: false,
		isLoading: false,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});

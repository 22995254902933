import { combineReducers } from "redux";

import { globalsReducer } from './globalsReducer';
import { sessionReducer } from './sessionReducer';

import capitalmkt from 'app-modules/capitalmkt/_reducers';
import cashmgt from 'app-modules/cashmgt/_reducers';
import dashboards from 'app-modules/dashboards/_reducers';
import moneymkt from 'app-modules/moneymkt/_reducers';
import onboard from 'app-modules/onboard/_reducers';
import research from 'app-modules/research/_reducers';
import reviewing from 'app-modules/reviewing/_reducers';

import settings from "app-redux/settings/Reducer";

import chatReducer from "precanned/redux/chats/Reducer";
import notesReducer from "precanned/redux/notes/Reducer";
import contactReducer from "precanned/redux/contacts/";
import emailReducer from "precanned/redux/email/";
import maintodoReducer from "precanned/redux/todos/Todos";
import todoReducer from "precanned/redux/todos/";

const rootReducer = combineReducers({
	globals: globalsReducer,
	session: sessionReducer,

	capitalmkt,
	cashmgt,
	dashboards,
	moneymkt,
	onboard,
	research,
	reviewing,
	
	settings,
	chatReducer,
	contactReducer,
	emailReducer,
	notesReducer,
	todoReducer,
	maintodoReducer,
});

export default rootReducer;

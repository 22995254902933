import { IRouteMenuData } from "utils/IRouteData";

import capitalmkt from "app-modules/capitalmkt/_routeshadow";

import cashmgt from "app-modules/cashmgt/_routeshadow";

import research from "app-modules/research/_routeshadow";


var ThemeRoutes: any[] = [
	...capitalmkt,

	...cashmgt,

	...research,
];

export default ThemeRoutes;

import { combineReducers } from 'redux';

import { toIsoDateString } from 'app-helpers/basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { DefNgNseExchangeId, EnumAllOptionValue, NullEntityId, oneYearAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-redux/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { SecInstrumentState, SecTransactionType } from 'app-model/enums/EqEnums';
import { PartnerState } from 'app-model/enums/FaEnums';

const stLists : IListComponentControlList = {
	EQAccountList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'EQAccountList',
		entityName: 'EQAccount',
		fetchUriTemplate: 'EQAccount/CustomerEQAccounts',
		exportUriTemplate: 'EQAccount/ExportCustomerEQAccounts',
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			enumParamClosureType: PartnerState.Active,
		},
	},
	EQAccountPick: {
		...fluxPickDefaults,
		actionNamespace: 'EQAccountPick',
		entityName: 'EQAccount',
		fetchUriTemplate: 'EQAccount/CustomerEQAccounts',
		filterValues: {
			searchText: '',
		},
	},

	EQContractNoteList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'EQContractNoteList',
		entityName: 'EQContractNote',
		fetchUriTemplate: 'EQContractNote/CustomerEQContracts',
		exportUriTemplate: 'EQContractNote/ExportCustomerEQContracts',
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			instrumentId: NullEntityId,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
		},
	},

	EQHoldingPryIssueList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'EQHoldingPryIssueList',
		entityName: 'EQHoldingPryIssue',
		fetchUriTemplate: 'EQHoldingPryIssue/CustomerEQHoldingPryIssues',
		exportUriTemplate: 'EQHoldingPryIssue/ExportCustomerEQHoldingPryIssues',
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			instrumentId: NullEntityId,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
		},
	},

	EQInstrumentList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentList',
		entityName: 'EQInstrument',
		fetchUriTemplate: 'EQInstrument',
		filterValues: {
			searchText: '',
			exchangeId: NullEntityId,
			enumParamInstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
			enumParamClosureType: SecInstrumentState.Active,
		},
	},
	EQInstrumentPick: {
        ...fluxPickDefaults,
		actionNamespace: 'EQInstrumentPick',
		entityName: 'EQInstrument',
		fetchUriTemplate: 'EQInstrument',
		filterValues: {
			searchText: '',
		},
	},

	EQOrderList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'EQOrderList',
		entityName: 'EQOrder',
		fetchUriTemplate: 'EQOrder/CustomerEQOrders',
		exportUriTemplate: 'EQOrder/ExportCustomerEQOrders',
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			instrumentId: NullEntityId,
			enumParamTradeType: EnumAllOptionValue, //SecTransactionType.BUY,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
		},
	},

	VALBondHtmHoldingList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'VALBondHtmHoldingList',
		entityName: 'VALBondHtmHolding',
		fetchUriTemplate: 'MyPortfolio/BondHtmHoldings',
		exportUriTemplate: 'MyPortfolio/BondHtmHoldings/Export',
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			instrumentId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},

	VALHoldingAggregateList: {
		...fluxListDefaults,
		//delayFetch: true,
		sizePage: 50,
		actionNamespace: 'VALHoldingAggregateList',
		entityName: 'VALHoldingAggregate',
		fetchUriTemplate: 'MyPortfolio/StockHoldings',
		exportUriTemplate: 'MyPortfolio/StockHoldings/Export',
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			instrumentId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	HRConfigView: {
		...fluxViewDefaults,
		actionNamespace: 'EQAccountView',
		entityName: 'EQAccount',
		fetchUriTemplate: 'EQAccount/CustomerEQAccount(${entityId})',
		entityId: -1,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});

import { IRouteMenuData } from "utils/IRouteData";

import reviewing from "app-modules/reviewing/_routeshadow";


var ThemeRoutes: any[] = [
	...reviewing,
];

export default ThemeRoutes;

import { $isNull } from 'app-helpers/basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { CorpActionClosure } from 'app-model/enums/EqEnums';
import { PartnerState } from 'app-model/enums/FaEnums';
import { SysActions } from 'app-model/SysActions';

import { fluxGlobalListDefaults, fluxViewDefaults } from './actionReducerCore';

const initialGlobalState: any = {
	defPageSize: 25,

	Global_FundSourcesView: {
		...fluxViewDefaults,
		actionNamespace: 'Global_FundSourcesView',
		entityName: 'ValueList',
		fetchUriTemplate: 'ValueList/Code(FundSources)',
	},
	Global_IncomeBracketsView: {
		...fluxViewDefaults,
		actionNamespace: 'Global_IncomeBracketsView',
		entityName: 'ValueList',
		fetchUriTemplate: 'ValueList/Code(IncomeBrackets)',
	},
	Global_TitlesView: {
		...fluxViewDefaults,
		actionNamespace: 'Global_TitlesView',
		entityName: 'ValueList',
		fetchUriTemplate: 'ValueList/Code(Titles)',
	},
	Global_MyUserView: {
		...fluxViewDefaults,
		actionNamespace: 'Global_MyUserView',
		entityName: 'metaUser',
		fetchUriTemplate: 'metaUser/GetMe',
		entityData: { ApplicationList: [], RoleList: [] } //supply default values for enable tests succeed
	},
	Global_MyPartnerView: {
		...fluxViewDefaults,
		actionNamespace: 'Global_MyPartnerView',
		entityName: 'Partner',
		fetchUriTemplate: 'Partner/GetMe',
	},

	Global_Entity_CaseContextList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_Entity_CaseContextList',
		entityName: 'CaseContext',
		fetchUriTemplate: 'PartnerCase/GetCaseContextEntity()',
	},
	Global_DocumentTypeList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_DocumentTypeList',
		entityName: 'DocumentType',
		fetchUriTemplate: 'DocumentType',
	},
	Global_EQAccountList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_EQAccountList',
		entityName: 'EQAccount',
		fetchUriTemplate: 'EQAccount/CustomerEQAccounts',
	},
	Global_EQCorpActionPryIssueList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_EQCorpActionPryIssueList',
		entityName: 'EQCorpActionPryIssue',
		fetchUriTemplate: `EQCorpActionPryIssue/DisplayList?ClosureType=${CorpActionClosure.Approved}`,
	},
	Global_EQInstrumentList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_EQInstrumentList',
		entityName: 'EQInstrument',
		fetchUriTemplate: 'EQInstrument',
	},
	Global_EQMarketOperatorList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_EQMarketOperatorList',
		entityName: 'EQMarketOperator',
		fetchUriTemplate: 'EQMarketOperator',
	},
	Global_FAFinHouseList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_FAFinHouseList',
		entityName: 'FAFinHouse',
		fetchUriTemplate: 'FAFinHouse',
	},
	Global_FundLoanTypeList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_FundLoanTypeList',
		entityName: 'FundLoanType',
		fetchUriTemplate: 'Fund/GetFundLoanTypeList()',
		expandProps: ['TypeFees'],
	},
	Global_NFTypeDefList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_NFTypeDefList',
		entityName: 'NFTypeDef',
		fetchUriTemplate: 'NFTypeDef',
	},
	Global_PartnerBankAcctList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_PartnerBankAcctList',
		entityName: 'PartnerBankAcct',
		fetchUriTemplate: 'PartnerBankAcct/CustomerBankAccts',
	},
	Global_PartnerBeneficiaryList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_PartnerBeneficiaryList',
		entityName: 'PartnerBeneficiary',
		fetchUriTemplate: 'PartnerBeneficiary/CustomerBeneficiaries',
	},
	Global_RefCountryList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_RefCountryList',
		entityName: 'RefCountry',
		fetchUriTemplate: 'RefCountry',
	},
	Global_RefStateList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_RefStateList',
		entityName: 'RefState',
		fetchUriTemplate: 'RefState',
	},
	Global_RefStateSubDivisions: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_RefCountryStateDivisionList',
		entityName: 'RefCountryStateDivisionList',
		fetchUriTemplate: 'RefCountry(${parentId})/StateDivisions',
		filterValues: {
			searchText: '',
			parentId: 12,
		},
	},
	Global_RefExchangeDefList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_RefExchangeDefList',
		entityName: 'RefExchangeDef',
		fetchUriTemplate: 'RefExchangeDef',
	},
	Global_ResCompanyList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_ResCompanyList',
		entityName: 'ResCompany',
		fetchUriTemplate: 'ResCompany',
	},
	Global_TermInvestTypeList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_TermInvestTypeList',
		entityName: 'TermInvestType',
		fetchUriTemplate: 'TermInvestType',
		expandProps: ['TypeFees'],
	},
	Global_TermLoanTypeList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_TermLoanTypeList',
		entityName: 'TermLoanType',
		fetchUriTemplate: 'TermLoanType',
		expandProps: ['TypeFees'],
	},
	Global_TRDiscLoanTypeList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_TRDiscLoanTypeList',
		entityName: 'TRDiscLoanType',
		fetchUriTemplate: 'TRDiscLoanType',
		expandProps: ['TypeFees'],
	},
	Global_TRTBillTypeList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_TRTBillTypeList',
		entityName: 'TRTBillType',
		fetchUriTemplate: 'TRTBillType',
	},
};

// globals to load and manage
export function globalsReducer(state: any = initialGlobalState, action: IFluxAction) {
	//console.log('globalsReducer called');

	const actionTypeParts = action.type.split('/');

	if (actionTypeParts.length == 2) {
		if (!$isNull(initialGlobalState[actionTypeParts[0]])) {
			switch (actionTypeParts[1]) {
				case SysActions.VIEW_FETCHDATA:
					return {
						...state,

						[action.payload.viewName]: {
							...state[action.payload.viewName],
							isLoading: true,
						},
					};

				case SysActions.VIEW_RECEIVEDATA:
					return {
						...state,

						[action.payload.viewName]: {
							...state[action.payload.viewName],

							fetched: !action.payload.data.error,
							isLoading: false,

							...action.payload.data,
							
							entityData: actionTypeParts[0] != 'Global_MyUserView'
								? action.payload.data.entityData
								: {
									...action.payload.data.entityData,

									RoleList: String((action.payload.data.entityData && action.payload.data.entityData.Roles) || '').split(','),
									ApplicationList: String((action.payload.data.entityData && action.payload.data.entityData.Applications) || '').split(','),
								}
						},
					};

				case SysActions.LIST_FETCHDATA:
					return {
						...state,

						[action.payload.listName]: {
							...state[action.payload.listName],
							isLoading: true,
						},
					};

				case SysActions.LIST_RECEIVEDATA:
					return {
						...state,

						[action.payload.listName]: {
							...state[action.payload.listName],
							fetched: !action.payload.data.error,
							isLoading: false,
							error: action.payload.data.error,
							totalCount: action.payload.data.totalCount,
							listData: action.payload.data.listData,
						},
					};

				default:
					return state;
			}
		}
	}

	return state;
};

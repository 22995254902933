import { lazy } from "react";

const BondList = lazy(() => import("app-modules/research/BondList"));
const EQCorpActionList = lazy(() => import("app-modules/research/EQCorpActionList"));
const EQInstrumentPriceList = lazy(() => import("app-modules/research/EQInstrumentPriceList"));
const EquityList = lazy(() => import("app-modules/research/EquityList"));

const ResReportList = lazy(() => import("app-modules/research/ResReportList"));

const RefExchangeDefList = lazy(() => import("app-modules/research/RefExchangeDefList"));


const moduleRoutes = 	{
	collapse: true,
	path: "/research",
	name: "Research",
	state: "researchpages",
	icon: "codepen",
	child: [
		{
			path: "/research/pricelists",
			name: "Price Lists",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: EQInstrumentPriceList,
		},
		{
			path: "/research/reports",
			name: "Reports**",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: ResReportList,
		},
		{
			path: "/research/corpactions",
			name: "Corporate Actions",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: EQCorpActionList,
		},
		{
			path: "/research/stocks",
			name: "Stocks",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: EquityList,
		},
		{
			path: "/research/bonds",
			name: "Bonds",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: BondList,
		},
		{
			path: "/research/mutualfunds",
			name: "Mutual Funds",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: RefExchangeDefList,
		},
		{
			path: "/research/exchanges",
			name: "Exchanges",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: RefExchangeDefList,
		},
	],
};

export default moduleRoutes;

import { AuthenticationResult, Configuration, EventMessage, EventType, LogLevel, PublicClientApplication, SilentRequest } from "@azure/msal-browser";

import { window } from 'utils/window';

// //we need this hack because the script tag in index.html doesn't seem to be working.
// import 'adal.app';
// import 'adal.host';

// console.log(window);
// console.log(window.grifAdalConfig);

export const endpoints = {
	appui: window.grifAdalConfig.appEndpoint,
	queryEndpoint: window.grifAdalConfig.queryEndpoint,
	cmdEndpoint: window.grifAdalConfig.cmdEndpoint,

	/**
	 * The Microsoft Graph endpoint url
	 */
	msgraph: 'https://graph.microsoft.com/v1.0',
};

export const resources = {
	bos: window.grifAdalConfig.griffinApiId,
	msgraph: '00000003-0000-0000-c000-000000000000',
};

export const apiRequest = {
	scopes: [
		`${window.grifAdalConfig.griffinApiUri}/user_impersonation`,
		'offline_access',
	]
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const graphRequest = {
	scopes: [
		"User.Read",
		// `${resources.msgraph}/User.Read`
	]
};

export const loginRequest = {
	scopes: [
		"openid",
		"offline_access"
	]
};

const loggerCallback = (logLevel, message, containsPii) => {
	// console.log("[MSAL]", message);
	if (containsPii) {
		return;
	}
	switch (logLevel) {
		case LogLevel.Error:
			console.error(message);
			return;
		case LogLevel.Info:
			console.info(message);
			return;
		case LogLevel.Verbose:
			console.debug(message);
			return;
		case LogLevel.Warning:
			console.warn(message);
			return;
	}
};

export const msalConfig: Configuration = {
	auth: {
		clientId: window.grifAdalConfig.appId,
		authority: `${window.grifAdalConfig.instance}/${window.grifAdalConfig.tenantId}/${window.grifAdalConfig.signInPolicy}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
		// authority: `https://login.microsoftonline.com/${window.grifAdalConfig.tenantId}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
		knownAuthorities: [
			`login.microsoftonline.com`,
			`vetivahqclients.b2clogin.com`,
			`${window.grifAdalConfig.instance}`,
			`${window.grifAdalConfig.instance}/${window.grifAdalConfig.tenantId}/${window.grifAdalConfig.signInPolicy}`,
		],
		redirectUri: window.location.origin, //window.location.origin, //window.location.href, //endpoints.appui,
		postLogoutRedirectUri: window.location.origin, //window.location.origin, //window.location.href, //endpoints.appui,
		navigateToLoginRequestUrl: true,
	},
	cache: {
		cacheLocation: "sessionStorage", // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		loggerOptions: {
			loggerCallback,
			piiLoggingEnabled: false,
			logLevel: LogLevel.Verbose,
		}
	}
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const handleLogin = (instance) => {
	console.log("kick off login");

    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
};

export function handleLogout(instance) {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback(
	(event: EventMessage) => {
		if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
			const payload = event.payload as AuthenticationResult;
			const account = payload.account;
			msalInstance.setActiveAccount(account);
		}
	}
);


export type RequestInitWithTimeout = RequestInit & { timeout?: number};

const defaultFetchTimeout = 8000; //time in milliseconds

const fetchWithTimeout = async (resource: string, options: RequestInitWithTimeout = {}): Promise<Response> => {
	const { timeout = defaultFetchTimeout } = options;

	const controller = new AbortController();
	const id = setTimeout(() => controller.abort(), timeout);

	const response = await fetch(resource, { ...options, signal: controller.signal });

	clearTimeout(id);

	return response;
}

const apiFetchCore = async (url: string, options: RequestInitWithTimeout, authRequest: SilentRequest): Promise<Response> => {
    // new msal way
	const account = msalInstance.getActiveAccount();
	if (!account) {
		throw Error("No active account! Verify a user has been signed in.");
	}

    const authResult = await msalInstance.acquireTokenSilent(
		{
			...authRequest,
			account: msalInstance.getActiveAccount(),
		}
	);
	const accessToken = authResult.accessToken;

	const headers: HeadersInit = {
		'Accept': 'application/json',
		'Content-Type': 'application/json',

		...((options || {}).headers || {}),

		Authorization: 'Bearer ' + accessToken
	};

	//this is our own version of a deep merge accounting for possibility of a null object
	//TODO - is it necessary to compensate for a null object or should we force callers to explicitly express intent by throwing an exception when options is null?
	const finalOptions: RequestInit = {
		...(options || { method: 'GET' }),

		headers
	};

	//return await fetchWithTimeout(url, finalOptions);
	return await fetch(url, finalOptions);
}

/**
  * A function for dispatching calls to Griffin's API endpoint
  *
  * @param url The URL for the request being made
  * @param options  Includes the body, headers, HTTP method and other options.
  *
  * @returns A promise that resolves to a Response object.
  */
export const adalApiFetch = async (url: string, options: RequestInitWithTimeout): Promise<Response> => {
	//TODO - test whether url starts with endpoints.bosapi

	return await apiFetchCore(url, options, apiRequest);
};

/**
  * A function for dispatching calls to the Microsoft Graph endpoint.
  *
  * @param url The URL for the request being made
  * @param options  Includes the body, headers, HTTP method and other options.
  *
  * @returns A promise that resolves to a Response object.
  */
export const adalGraphFetch = async (url: string, options: RequestInitWithTimeout): Promise<Response> => {
	//TODO - test whether url starts with endpoints.msgraph

	return await apiFetchCore(url, options, graphRequest);
};

import { ChangeEventHandler, EventHandler, FormEvent, ReactNode } from 'react';

/**
 * A marker interface for non-value returning functions that take no parameters
 * 
 * @remarks
 * You can use this type to represent a function that can be passed as a parameter without explicitly declaring a custom type. 
 * The referenced function must correspond to the function signature that is defined by this type. 
 * This means that the referenced function must have no parameters and must be void returning ie must not return a value.
 * 
 * @returns void
 */
export interface IAction {
	(): void;
}


/**
 * A marker interface for value returning functions that take no parameters
 * 
 * @remarks
 * You can use this type to represent a function that can be passed as a parameter without explicitly declaring a custom type. 
 * The referenced function must correspond to the function signature that is defined by this type. 
 * This means that the referenced function must have no parameters and must return a value of type [[TReturn]].
 * 
 * @typeparam TReturn the type of the return value of the function
 * @returns returns a value of type [[TReturn]]
 */
export interface IFunc<TReturn> {
	(): TReturn;
}


/**
 * A marker interface for non-value returning functions that take 1 parameter
 * 
 * @remarks
 * You can use this type to represent a function that can be passed as a parameter without explicitly declaring a custom type. 
 * The referenced function must correspond to the function signature that is defined by this type. 
 * This means that the referenced function must take 1 parameter of type [[TParam1]] and must be void returning ie must not return a value.
 *
 * @typeparam TParam1 the type of the parameter passed to the function
 * @returns void
 */
export interface IAction1<TParam1> {
	(param1: TParam1): void;
}


/**
 * A marker interface for value returning functions that takes 1 parameter
 * 
 * @remarks
 * You can use this type to represent a function that can be passed as a parameter without explicitly declaring a custom type. 
 * The referenced function must correspond to the function signature that is defined by this type. 
 * This means that the referenced function must take 1 parameter of type [[TParam1]] and must return a value of type [[TReturn]].
 * 
 * @typeparam TParam1 the type of the parameter passed to the function
 * @typeparam TReturn the type of the return value of the function
 * @returns returns a value of type [[TReturn]]
 */
export interface IFunc1<TParam1, TReturn> {
	(param1: TParam1): TReturn;
}


/**
 * A marker interface for value returning functions that takes 2 parameters
 * 
 * @remarks
 * You can use this type to represent a function that can be passed as a parameter without explicitly declaring a custom type. 
 * The referenced function must correspond to the function signature that is defined by this type. 
 * This means that the referenced function must take 2 parameters of type [[TParam1],[TParam2]] and must be void returning ie must not return a value.
 * 
 * @typeparam TParam1 the type of the parameter passed to the function
 * @typeparam TParam2 the type of the second parameter passed to the function
 * @typeparam TReturn the type of the return value of the function
 * @returns returns a value of type [[TReturn]]
 */
export interface IAction2<TParam1, TParam2> {

	(param1: TParam1, param2?: TParam2): void;
}

/**
 * A marker interface for value returning functions that takes 2 parameters
 *
 * @remarks
 * You can use this type to represent a function that can be passed as a parameter without explicitly declaring a custom type.
 * The referenced function must correspond to the function signature that is defined by this type.
 * This means that the referenced function must take 2 parameter of type [[TParam1],[TParam2]] and must return a value of type [[TReturn]].
 *
 * @typeparam TParam1 the type of the first parameter passed to the function
 * @typeparam TParam2 the type of the second parameter passed to the function
 * @typeparam TReturn the type of the return value of the function
 * @returns returns a value of type [[TReturn]]
 */
export interface IFunc2<TParam1, TParam2, TReturn> {
	(param1: TParam1, param2?: TParam2): TReturn;
}


/**
 * A marker interface for value returning functions that takes 3 parameters
 * 
 * @remarks
 * You can use this type to represent a function that can be passed as a parameter without explicitly declaring a custom type. 
 * The referenced function must correspond to the function signature that is defined by this type. 
 * This means that the referenced function must take 3 parameters of type [[TParam1],[TParam2],[TParam3]] and must be void returning ie must not return a value.
 * 
 * @typeparam TParam1 the type of the parameter passed to the function
 * @typeparam TParam2 the type of the second parameter passed to the function
 * @typeparam TParam3 the type of the third parameter passed to the function
 * @typeparam TReturn the type of the return value of the function
 * @returns returns a value of type [[TReturn]]
 */
export interface IAction3<TParam1, TParam2, TParam3> {
	(param1: TParam1, param2: TParam2, param3?: TParam3): void;
}


/**
 * A marker interface for value returning functions that takes 3 parameters
 *
 * @remarks
 * You can use this type to represent a function that can be passed as a parameter without explicitly declaring a custom type.
 * The referenced function must correspond to the function signature that is defined by this type.
 * This means that the referenced function must take 3 parameter of type [[TParam1],[TParam2],[TParam3]] and must return a value of type [[TReturn]].
 *
 * @typeparam TParam1 the type of the first parameter passed to the function
 * @typeparam TParam2 the type of the second parameter passed to the function
 * @typeparam TParam3 the type of the third parameter passed to the function
 * @typeparam TReturn the type of the return value of the function
 * @returns returns a value of type [[TReturn]]
 */
export interface IFunc3<TParam1, TParam2, TParam3, TReturn> {
	(param1: TParam1, param2: TParam2, param3?: TParam3): TReturn;
}


/**
 * A marker interface for value returning functions that takes 4 parameters
 * 
 * @remarks
 * You can use this type to represent a function that can be passed as a parameter without explicitly declaring a custom type. 
 * The referenced function must correspond to the function signature that is defined by this type. 
 * This means that the referenced function must take 4 parameters of type [[TParam1],[TParam2],[TParam3],[TParam4]] and must be void returning ie must not return a value.
 * 
 * @typeparam TParam1 the type of the parameter passed to the function
 * @typeparam TParam2 the type of the second parameter passed to the function
 * @typeparam TParam3 the type of the third parameter passed to the function
 * @typeparam TParam4 the type of the fourth parameter passed to the function
 * @typeparam TReturn the type of the return value of the function
 * @returns returns a value of type [[TReturn]]
 */
export interface IAction4<TParam1, TParam2, TParam3, TParam4> {
	(param1: TParam1, param2: TParam2, param3?: TParam3, param4?: TParam4): void;
}


/**
 * A marker interface for value returning functions that takes 4 parameters
 *
 * @remarks
 * You can use this type to represent a function that can be passed as a parameter without explicitly declaring a custom type.
 * The referenced function must correspond to the function signature that is defined by this type.
 * This means that the referenced function must take 3 parameter of type [[TParam1],[TParam2],[TParam3],[TParam4]] and must return a value of type [[TReturn]].
 *
 * @typeparam TParam1 the type of the first parameter passed to the function
 * @typeparam TParam2 the type of the second parameter passed to the function
 * @typeparam TParam3 the type of the third parameter passed to the function
 * @typeparam TParam3 the type of the fourth parameter passed to the function
 * @typeparam TReturn the type of the return value of the function
 * @returns returns a value of type [[TReturn]]
 */
export interface IFunc4<TParam1, TParam2, TParam3, TParam4, TReturn> {
	(param1: TParam1, param2: TParam2, param3?: TParam3, param4?: TParam4): TReturn;
}

export const noOpParameterLess = () => {};

export const noOpHtmlEvent: EventHandler<FormEvent<ReactNode>> = (evt) => {};

export const onChangeNoOpEvent: ChangeEventHandler<Element> = (evt) => {};

export const noOpActionDispatch = (actionName: string, actionParams: any) => {};


/**
 * A function to check if a value is null or undefined
 *
 * @remarks
 * This function accepts one parameter of any data type and checks if it is null or undefined.
 *
 * @typeparam T The type of value passed to the function
 * @param value A parameter that can be of a template type
 * @returns A boolean. [True] indicating [value] is null or undefined; otherwise false
 */
export const $isNull = <T>(value: T): boolean => {	
	return (value === null || value === undefined);
};


/**
 * A function to check if a value is an object/function
 * 
 * @remarks
 * This function takes a value and confirms whether it is a function. It accepts one parameter.
 *
 * @param value A parameter of any type
 * @returns A boolean. [True] indicating [value] is a function; otherwise false
 */
export const $isFunction = (value: any): boolean => {
	return !$isNull(value) && {}.toString.call(value) === '[object Function]'; 
};


/**
 * A function to check if the supplied string is empty or undefined or null
 * 
 * @remarks
 * This function takes a value and confirms whether it is empty or a null value. It accepts a parameter of type [[value]].
 * It is a boolean returning function. 
 * 
 * @param value A string type parameter.
 * @returns A boolean, _true_ when value is null, undefined or an empty string; false otherwise.
 */
export function $isNullOrEmpty(value: string): boolean {
	return (value === null || value === undefined || value === '');
}


/**
 * A function that returns a default value if the value is null or undefined.
 * 
 * @remarks 
 * This function takes two parameters [[value]] and [[defaultVal]] of type [[T]].
 * It returns a value of type [[T]].
 * 
 * @typeparam T The data type of [[value]] or [[defaultVal]] passed in
 * @param value A nullable value to be substituted if null or undefined.
 * @param defaultVal An alternative value to be returned when value is null or undefined.
 * @returns T; value if it is not null or undefined, otherwise defaultVal
 */
export function $nullCoalesce<T>(value: T, defaultVal: T ): T {
	if (defaultVal === undefined) 
		throw new Error('defaultVal cannot be undefined.');
	
	if (value === null || value === undefined)
		return defaultVal;
	
	return value;
}


/**
 * A function that returns a default value if the value is null or undefined.
 * 
 * @remarks 
 * This function takes two parameters [[value]] and [[default]] of type [[IFunc<T>]].
 * It returns a value of type [[T]].
 * 
 * @typeparam T The type returned by [[value]] and [[defaultVal]] functions
 * @param value A nullable function to be substituted if null or undefined.
 * @param defaultVal An alternative function to be executed when value is null or undefined.
 * @returns T; the return of [[value]] if it is not null or undefined, otherwise the return of [[defaultVal]]
 */
export function $nullCoalesceFunc<T>(value: IFunc<T>, defaultVal: IFunc<T>): T {
	if (defaultVal === null || defaultVal === undefined) 
		throw new Error('defaultVal function must be concrete.');
	
	if (value === null || value === undefined)
		return defaultVal();
		
	return value();
}


export function $deepEqual(obj1: any, obj2: any) {
	if(obj1 === obj2) // it's just the same object. No need to compare.
		return true;

	// if(isPrimitive(obj1) && isPrimitive(obj2)) // compare primitives
	//     return obj1 === obj2;
	const obj1Type = typeof obj1;
	const obj2Type = typeof obj2;
	
	if (obj1Type !== 'object' || obj2Type !== 'object' 
		|| obj1Type == null || obj1Type == undefined || obj2Type == null || obj2Type == undefined) 
		return false;

	const obj1Keys = Object.keys(obj1);
	const obj2Keys = Object.keys(obj2);

	if (obj1Keys.length !== obj2Keys.length)
		return false;

	// compare objects with same number of keys
	for (let i = 0; i < obj1Keys.length; i++)
	{
		const key = obj1Keys[i];

		if (key !== obj2Keys[i]) //other object doesn't have this prop/key
			return false;
		
		if (!$deepEqual(obj1[key], obj2[key])) 
			return false;
	}

	return true;
}


/**
 * A function for creating a new array with any length
 * 
 * @remarks
 * This function creates a new array of [[size]]. The first elements in the array is [[startAt]]. 
 * Each element is created by adding the index of the element to the [[startAt]] value.
 * It is a number type array returning function, ie, the elements of the array must be numbers
 * 
 * @param size Indicates the desired size/length of the returned number array
 * @param startAt The starting point of the number range.
 * @param increment The step count or increment size between consecutive numbers in the array.
 * @returns An array of numbers
 */
export function rangeOfNum(size: number, startAt: number = 0, increment: number = 1): number[] {
	if (size === null || size === undefined) 
		throw new Error('size must be specified.');
	
	if (startAt === null) 
		throw new Error('startAt must be specified.');
	
	if (increment === null) 
		throw new Error('increment must be specified.');
	
	return Array.from({ length: size }, (arr, idx) => (idx * increment) + startAt);
}


/** 
 * A function to generate a range of characters and return as a string
 * 
 * @remarks
 * This function creates a new string based on an array of ASCII values generated with [[startChar]] and [[endChar]] as the start and end xters of that array.
 * 
 * @param startChar first element of the character range to return
 * @param endChar last element of the character range to return
 * @returns A string type returned value
 */
export function rangeOfChar(startChar: string, endChar: string): string {
	if (startChar === null || startChar === undefined) 
		throw new Error('startChar must be specified.');
	
	if (endChar === null || endChar === undefined) 
		throw new Error('endChar must be specified.');
	
	return String.fromCharCode(
		...rangeOfNum((endChar.charCodeAt(0) - startChar.charCodeAt(0)) + 1, startChar.charCodeAt(0), 1)
	);
}


/**
 * A function to replace all occurrences of a character sequence in a string
 * 
 * @param str The string to search
 * @param find The character sequence to be stripped from [[str]]
 * @param replace The character sequence to be replaced/inserted into [[str]]
 * @returns A string that has no occurrences of [[find]]
 */
export function $replaceAll(str: string, find: string, replace: string = ''): string {
	if (str === null || str === undefined) 
		throw new Error('str must be specified.');
	
	if (find === null || find === undefined) 
		throw new Error('find must be specified.');
	
	if (replace === null) 
		throw new Error('replace must be specified.');
	
	const pattern = new RegExp(find, 'g');

	return str.replace(pattern, replace);
}


/**
 * A function to convert the first letter of every word in a string to upper case.
 * 
 * @remarks
 * This function splits [[str]] a given string based on the [[splitOn]] parameter. 
 * It converts the first letter in each of the splitted words to capital case and converts the rest substring to lowercase
 * It is a string returning function. 
 * 
 * @param str A string containing multiple words whose first letters we want to conver to upper case.
 * @param splitOn A string indicating the word delimiter in str. The default value is space ' '.
 * 
 * @returns returns a string with the first letter of each word capitalized.
 */
export function toTitleCase(str: string, splitOn: string = ' '): string {
	if (str === null || str === undefined) 
		throw new Error('str must be specified.');
	
	if (splitOn === null || splitOn === '') 
		throw new Error('find must be specified and must not be empty.');
	
	return str
		.split(splitOn)
		.map(i => i[0].toUpperCase() + i.substring(1).toLowerCase())
		.join(splitOn);
}


/**
 * A function to extract data from a complex data path. 
 * 
 * @remarks
 * It returns null if the data is null.
 * It returns the existing value if the length of the path is greater than the [currentIndex]
 * It delegates to the private function [[getDataFromPathWorker]] to recursively call/iterate through each path segement.
 * 
 * @param entity The entity/object containing the value we want to extract.
 * @param path The property path to the desired value passed as a string.
 * @returns Returns the value at the end of the data path.
 */
export function getDataFromPath(entity: any, path: string = '.'): any {
	/**
	 * A function delegated to handle the data extraction from the a given path
	 *
	 * @param data A parameter of any data type
	 * @param path A parameter of array string data type
	 * @param currentIndex A parameter of number data type
	 * @returns Returns a value of any data type
	 */
	const getDataFromPathWorker = (data: any, path: string[], currentIndex: number): any => {
		
		if ($isNull(data))
			return null;

		const currentValue = data[path[currentIndex]];

		const nextIndex = currentIndex + 1;

		if (nextIndex >= path.length)
			return currentValue;
		else
			return getDataFromPathWorker(currentValue, path, nextIndex);
	}

	if ($isNull(entity))
		return null;

	if ($isNullOrEmpty(path) || path == '.')
		return entity;

	return getDataFromPathWorker(entity, String(path).split('.'), 0);
}


/**
 * A function to get previous days(s)
 * 
 * @remarks
 * This function gets the previous months by subtracting a number from any month.
 * It is date return function.
 *
 * @export
 * @param val A parameter of any data type
 * @param backwards A parameter of number data type
 * @returns Returns a Date type value
 */
export function getDaysAfter(val: any, forwards: number = 1): Date | null {
	if ($isNull(forwards))
		throw new Error('backwards must have a value.');
	
	if (forwards < 0) 
		throw new Error('backwards cannot be negative.');

	if ($isNullOrEmpty(val))
		return null;
	
	let anchorDate = new Date(val);

	anchorDate.setDate(anchorDate.getDate() + forwards);

	return anchorDate;
}


/**
 * A function to get previous days(s)
 * 
 * @remarks
 * This function gets the previous months by subtracting a number from any month.
 * It is date return function.
 *
 * @export
 * @param val A parameter of any data type
 * @param backwards A parameter of number data type
 * @returns Returns a Date type value
 */
export function getDaysPrior(val: any, backwards: number = 1): Date | null {
	if ($isNull(backwards))
		throw new Error('backwards must have a value.');
	
	if (backwards < 0) 
		throw new Error('backwards cannot be negative.');

	if ($isNullOrEmpty(val))
		return null;
	
	let anchorDate = new Date(val);

	anchorDate.setDate(anchorDate.getDate() - backwards);

	return anchorDate;
}


/**
 * A function to get previous month(s)
 * 
 * @remarks
 * This function gets the previous months by subtracting a number from any month.
 * It is date return function.
 *
 * @export
 * @param val A parameter of any data type
 * @param forwards A parameter of number data type
 * @returns Returns a Date type value
 */
export function getMonthsAfter(val: any, forwards: number = 1): Date | null {
	if ($isNull(forwards))
		throw new Error('backwards must have a value.');
	
	if (forwards < 0) 
		throw new Error('backwards cannot be negative.');

	if ($isNullOrEmpty(val))
		return null;
	
	let anchorDate = new Date(val);

	anchorDate.setMonth(anchorDate.getMonth() + forwards);

	return anchorDate;
}

/**
 * A function to get previous month(s)
 * 
 * @remarks
 * This function gets the previous months by subtracting a number from any month.
 * It is date return function.
 *
 * @export
 * @param val A parameter of any data type
 * @param backwards A parameter of number data type
 * @returns Returns a Date type value
 */
export function getMonthsPrior(val: any, backwards: number = 1): Date | null {
	if ($isNull(backwards))
		throw new Error('backwards must have a value.');
	
	if (backwards < 0) 
		throw new Error('backwards cannot be negative.');

	if ($isNullOrEmpty(val))
		return null;
	
	let anchorDate = new Date(val);

	anchorDate.setMonth(anchorDate.getMonth() - backwards);

	return anchorDate;
}


/**
 * A function format a date to ISO date. 
 *
 * @param val1 The date to be converted represented as a string/number/Date
 * @returns An ISO string representation of the date supplied
 */
export function getMonthEnd(val1: string | number | Date): string | null {
	if ($isNull(val1) || val1 == "")
		return null;

	const val = new Date(val1);

	//return val.toISOString().slice(0, 10);

	return val.getFullYear() + "-" + appendLeadingZeroes(val.getMonth() + 1) + "-28";
}


/**
 * This functions counts forward by [[forwards]] number of years.
 * 
 * @remarks
 * The function gets the year(s) by adding a number to any year.
 * It is a date returning function. Returns a date ahead of the year from {val}
 *
 * @export
 * @param val A parameter of any data type
 * @param forwards A parameter of number data type
 * @returns Returns a date type value. 
 */
export function getYearsAfter(val: any, forwards: number = 1): Date | null {
	if ($isNull(forwards))
		throw new Error('forwards must have a value.');
	
	if (forwards < 0) 
		throw new Error('forwards cannot be negative.');

	if ($isNullOrEmpty(val))
		return null;
	
	let anchorDate = new Date(val);

	anchorDate.setFullYear(anchorDate.getFullYear() + forwards);

	return anchorDate;
}


/**
 * A function to get previous year(s)
 *
 * @remarks
 * The function gets the previous year(s) by subtracting a number from any year.
 * It is a date returning function. Returns a date ahead of the year from {val}
 * 
 * @param val A parameter of any data type
 * @param backwards A parameter of number data type
 * @returns Returns a date type value.  
 */
export function getYearsPrior(val: any, backwards: number = 1): Date | null {
	if ($isNull(backwards))
		throw new Error('backwards must have a value.');
	
	if (backwards < 0) 
		throw new Error('backwards cannot be negative.');

	if ($isNullOrEmpty(val))
		return null;
	
	let anchorDate = new Date(val);

	anchorDate.setFullYear(anchorDate.getFullYear() - backwards);

	return anchorDate;
}


/**
 * A function to descriptively render a currency conversion rate.
 *
 * @param currency The currency being converted to
 * @param rate The rate of conversion from the base currency
 * @returns A human readable expression of currency conversion rate.
 */
export function displayCurrencyRate(currency: string, rate: number): string | null {
	if ($isNull(currency) || $isNull(rate))
		return null;

	const rateString = (rate >= 0 && rate !== 1 ? ` @ ${toDecPlacesExact(rate, 2)}` : '');

	return `${currency}${rateString}`;
}


/**
 * A function to convert a number to accounting display format
 *
 * @param val The numeric value to be formatted represented as number or string (already converted from a number)
 * @param locales The locale(s) to use for number formatting
 * @returns A string representation of the supplied number
 */
export function toAcctValueDisplay(val: string | number, locales: undefined | string | string[] = undefined): string | null {
	if ($isNull(val))
		return null;

	let num = new Number(val);
	let isNegative = num < 0;

	num = isNegative ? Math.abs(num.valueOf()) : num;

	return (isNegative ? '(' : '') + num.toLocaleString(locales, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + (isNegative ? ')' : '');
}


/**
 * A function to format the display of whole numbers. It accepts two parameters ie the value and language
 * It returns a string representing the formatted whole number
 *
 * @export
 * @param val The numeric value to be formatted represented as number or string (already converted from a number)
 * @param locales The locale(s) to use for number formatting
 * @returns A string representation of the supplied number
 */
export function toWholeNumber(val: string | number, locales: undefined | string | string[] = undefined): string | null {
	if ($isNull(val))
		return null;

	let num = new Number(val);

	return $isNull(val) ? null : num.toLocaleString(locales, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
}


/**
 * A function to format the display of a value to 2 decimal places. It accepts two parameters ie the value and language
 *
 * @export
 * @param val  The numeric value to be formatted represented as number or string (already converted from a number)
 * @param dp  The number of decimal places to be maintained in the output string
 * @param locales The locale(s) to use for number formatting
 * @returns A string representation of the supplied number
 */
export function toDecPlacesExact(val: string | number, dp: number, locales: string | string[] = 'en'): string | null {
	if ($isNull(val))
		return null;

	if (dp === null || dp === undefined)
		throw new Error('dp must have a value.');

	let num = new Number(val);

	return $isNull(val) ? null : num.toLocaleString(locales, { minimumFractionDigits: dp, maximumFractionDigits: dp });
}


/**
 * A function to format the display of a value to 2 decimal places. It accepts two parameters ie the value and language
 *
 * @export
 * @param val  The numeric value to be formatted represented as number or string (already converted from a number)
 * @param locales The locale(s) to use for number formatting
 * @returns A string representation of the supplied number
 */
export function toGeneralNumber(val: string | number, locales: string | string[] = 'en'): string | null {
	if ($isNull(val))
		return null;

	let num = new Number(val);

	return $isNull(val) ? null : num.toLocaleString(locales, { minimumFractionDigits: 0, maximumFractionDigits: 8 });
}


/**
 * A function to add additional zeros to a number. It accepts one parameter which represents the number of zeros you want to append. 
 *
 * @remarks We may have a numeric value we want to return as a string but want to have a consistent width for the numbers returned.
 *
 * @param n The numeric value to be formatted represented as number or string (already converted from a number)
 * @returns A string type returned value	
 */
export function appendLeadingZeroes(n : string | number, width: number = 2): string | null {
	if (width === null) 
		throw new Error('width must have a value.');
	
	if (width < 2) 
		throw new Error('width cannot be less than 2.');

	if (n === null || n === undefined)
		return null;
	
	if (n < 0)
		throw new Error('n cannot be less than 0.');

	let num = new Number(n);

	//numdigits
	let numDigits = `${num}`.length;

	//numzeros
	let numZeros = width - numDigits;

	let prefix = numZeros > 0 ? '0'.repeat(numZeros) : '';
	
	return `${prefix}${num}`;
}


/**
 * A function format a date to ISO date. 
 *
 * @param val1 The date to be converted represented as a string/number/Date
 * @returns An ISO string representation of the date supplied
 */
export function toIsoDateString(val1: string | number | Date): string | null {
	if ($isNull(val1) || val1 == "")
		return null;

	const val = new Date(val1);

	//return val.toISOString().slice(0, 10);

	return val.getFullYear() + "-" + appendLeadingZeroes(val.getMonth() + 1) + "-" + appendLeadingZeroes(val.getDate());
}


/**
 * A function to format a value to ISO date-time. 
 *
 * @param val1 The date to be converted represented as a string/number/Date
 * @returns An ISO string representation of the local date-time supplied
 */
export function toIsoDateTimeLocalString(val1: string | number | Date): string | null {
	if ($isNull(val1) || val1 == "")
		return null;

	const val = new Date(val1);

	//return val.toISOString().slice(0, 18);

	return val.getFullYear() + "-" + appendLeadingZeroes(val.getMonth() + 1) + "-" + appendLeadingZeroes(val.getDate()) + " " + appendLeadingZeroes(val.getHours()) + ":" + appendLeadingZeroes(val.getMinutes()) + ":" + appendLeadingZeroes(val.getSeconds());
}


/**
 * A function to format a value to ISO date-time. 
 *
 * @param val1 The date to be converted represented as a string/number/Date
 * @returns An ISO string representation of the local date-time supplied
 */
export function toIsoDateTimeLocalInputString(val1: string | number | Date): string | null {
	if ($isNull(val1) || val1 == "")
		return null;

	const val = new Date(val1);

	//return val.toISOString().slice(0, 18);

	return val.getFullYear() + "-" + appendLeadingZeroes(val.getMonth() + 1) + "-" + appendLeadingZeroes(val.getDate()) + "T" + appendLeadingZeroes(val.getHours()) + ":" + appendLeadingZeroes(val.getMinutes());
}


/**
 * A function to format a value to ISO time. 
 *
 * @param val1 The date/time to be converted represented as a string/number/Date
 * @returns An ISO string representation of the local time supplied
 */
export function toIsoTimeLocalString(val1: string | number | Date): string | null {
	if ($isNull(val1) || val1 == "")
		return null;

	const val = new Date(val1);

	//return val.toISOString().slice(0, 18);

	return appendLeadingZeroes(val.getHours()) + ":" + appendLeadingZeroes(val.getMinutes()) + ":" + appendLeadingZeroes(val.getSeconds());
}

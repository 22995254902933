import { lazy } from "react";

const HeadsUpDisplay = lazy(() => import("app-modules/dashboards/HeadsUpDisplay"));
const PortfolioSummary = lazy(() => import("app-modules/dashboards/PortfolioSummary"));
const TransactionSummary = lazy(() => import("app-modules/dashboards/TransactionSummary"));

const moduleRoutes = 	{
	collapse: true,
	path: "/dashboards",
	name: "Dashboards",
	state: "dashboardpages",
	icon: "home",
	child: [
		{
			path: "/dashboards/headsupdisplay",
			name: "Heads Up Display",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: HeadsUpDisplay,
		},
		{
			path: "/dashboards/portfoliosummary",
			name: "Portfolio Summary",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: PortfolioSummary,
		},
		{
			path: "/dashboards/transactionsummary",
			name: "Transaction Summary",
			mini: "B",
			icon: "mdi mdi-adjust",
			component: TransactionSummary,
		},
	],
};

export default moduleRoutes;

import { IRouteMenuData } from "utils/IRouteData";

import CapitalMktRoutes from "app-modules/capitalmkt/_routemenu";
import DashboardRoutes from "app-modules/dashboards/_routemenu";
import MoneyMktRoutes from "app-modules/moneymkt/_routemenu";
import CashMgtRoutes from "app-modules/cashmgt/_routemenu";
import ResearchRoutes from "app-modules/research/_routemenu";


var ThemeRoutes: IRouteMenuData[] = [
	{
		navlabel: true,
		name: "Personal",
		icon: "mdi mdi-dots-horizontal",
	},

	DashboardRoutes,

	CapitalMktRoutes,

	MoneyMktRoutes,

	CashMgtRoutes,

	ResearchRoutes,
	
	{
		navlabel: true,
		name: "Precanned",
		icon: "mdi mdi-dots-horizontal",
	},

	{
		path: "*",
		pathTo: "/dashboards/headsupdisplay",
		name: "Dashboard",
		redirect: true,
	},
];

export default ThemeRoutes;

import { lazy } from "react";

const PartnerPaymentView = lazy(() => import("app-modules/cashmgt/PartnerPaymentView"));

const moduleRoutes = [
	{
		path: "/cashmgt/withdrawals/:id/view",
		component: PartnerPaymentView,
	},
];

export default moduleRoutes;

import { FunctionComponent, Suspense } from 'react';
import { HashRouter } from "react-router-dom";

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';

import Spinner from "app-framework/spinner/Spinner";

import { LogonAuthenticatedRouteMap } from 'app-layouts/LogonAuthenticatedRouteMap';
import { LogonUnauthenticatedRouteMap } from 'app-layouts/LogonUnauthenticatedRouteMap';


initializeIcons();
initializeFileTypeIcons();

export const App: FunctionComponent<any> = () => {
	const { inProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	return (
		<HashRouter>
			<Suspense fallback={<Spinner />}>
				{
					(isAuthenticated) ? <LogonAuthenticatedRouteMap />
					: <LogonUnauthenticatedRouteMap />
				}
			</Suspense>
		</HashRouter>
	);
};
